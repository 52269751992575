<template>
    <div>
        <mercur-item :to="getRouteForName('SuppliersView')">
            <i class="fas fa-user-friends"></i><span>Suppliers</span>
        </mercur-item>
        <mercur-item :to="getRouteForName('AutoUpdatedProductsOverview')">
            <i class="fas fa-download"></i><span>Auto-updated products</span>
        </mercur-item>
        <mercur-item :to="getRouteForName('ProductsForApprovalOverview')">
            <i class="fas fa-clipboard-check"></i><span>Products for approval</span>
        </mercur-item>
        <!--<mercur-item :to="getRouteForName('SupplierIntegrationsView')">
            <i class="fas fa-sign-in-alt"></i><span>Integration</span>
        </mercur-item>
        <mercur-item :to="getRouteForName('FactorsOverview')">
            <i class="fas fa-calculator"></i><span>Factors</span>
        </mercur-item>
        <mercur-item :to="getRouteForName('ArtworkFilesOverview')">
            <i class="fas fa-file-image"></i><span>Artwork files</span>
        </mercur-item>-->
    </div>
</template>

<script>

export default {
    name: 'SuppliersSideMenu',
    methods: {
        getRouteForName (section, params) {
            return this.$router.resolve({
                name: section,
                params: {
                    ...params,
                },
            }).href
        },
    },
}
</script>

<style lang="scss" scoped>
.mercur-item i {
    max-width: 18px;
}
</style>
