import {
    required,
    requiredIf,
    minLength,
} from 'vuelidate/lib/validators'

export default {
    DEBUG: process.env.VUE_APP_DEBUG === 'true',
    AG_GRID_LICENSE_KEY: process.env.VUE_APP_AG_GRID_LICENSE_KEY,
    API: {
        URL: process.env.VUE_APP_API_URL,
        ROUTES: {
            LOGIN: '/auth/login',
            ACCOUNT: {
                REQUEST_PASSWORD_RECOVERY: '/auth/password/recovery-request',
                RESET_PASSWORD: '/auth/password/recovery'
            },
            ACCESS_CONTROL: {
                OVERVIEW: '/access-control/overview',
                OVERVIEW_FOR_ORGANISATION: '/access-control/overview/organisation/{organisationId}',
                OVERVIEW_ORGANISATIONS: '/access-control/overview/organisations',
                ACCOUNT: {
                    REVOKE: '/access-control/account/revoke/{accountId}',
                    REACTIVATE: '/access-control/account/reactivate/{accountId}',
                    CREATE: '/access-control/account/create/{organisationId}',
                    GET: '/access-control/account/get/{accountId}',
                    UPDATE: '/access-control/account/update/{accountId}',
                    VIRTUAL_SUPPLIER: {
                        REVOKE: '/access-control/account/revokeVirtualSupplierAccount/{accountId}',
                        REACTIVATE: '/access-control/account/reactivateVirtualSupplierAccount/{accountId}',
                        CREATE: '/access-control/account/createVirtualSupplierAccount/{organisationId}',
                        UPDATE: '/access-control/account/updateVirtualSupplierAccount/{accountId}',
                    },
                },
                PERMISSIONS: {
                    OVERVIEW: '/access-control/permissions/overview',
                    ADD: '/access-control/permissions/create',
                    REVOKE: '/access-control/permissions/revoke',
                    GET: '/access-control/permissions/get/{permissionId}',
                    UPDATE: '/access-control/permissions/update'
                },
                ROLES: {
                    OVERVIEW: '/access-control/roles/overview',
                    ADD: '/access-control/roles/create',
                    GET: '/access-control/roles/get/{roleId}',
                    UPDATE: '/access-control/roles/update',
                    REVOKE: '/access-control/roles/revoke'
                },
                ACTIONS: {
                    OVERVIEW: '/access-control/actions/overview'
                }
            },
            HOLIDAYS: {
                BY_COUNTRY_AND_YEAR: '/holidays/{countryCode}/{year}',
            },
            EXTRA_DAYS: {
                OVERVIEW: '/extradays/overview',
                GET: '/extradays/{extraDaysConfigurationId}/get',
                ADD: '/extradays/add',
                REMOVE: '/extradays/{extraDaysConfigurationId}/remove',
            },
            HS_CODES: {
                OVERVIEW: '/hscodes/overview',
                GET: '/hscodes/{productConfigurationTemplateId}/get',
                GET_AVAILABLE_PRODUCT_TEMPLATES: '/hscodes/getAvailableProductTemplates',
                ADD: '/hscodes/{productConfigurationTemplateId}/add',
                UPDATE: '/hscodes/{productConfigurationTemplateId}/update',
                REMOVE: '/hscodes/{productHsCodeId}/remove',
            },
            PRODUCT_CONFIGURATION_TEMPLATES: {
                GET_KEY_ATTRIBUTE: '/productconfigurationtemplates/{productConfigurationTemplateId}/getKeyAttribute',
            },
            FACTORS: {
                OVERVIEW: '/factors/overview',
                GET: '/factors/{supplierFactorId}/get',
                UPDATE: '/factors/{supplierFactorId}/update',
                ADD: '/factors/add',
                REMOVE: '/factors/{supplierFactorId}/remove',
            },
            CUT_OFF_TIME_ADJUSTMENT: {
                OVERVIEW: '/cutofftimes/overview',
                GET: '/cutofftimes/{cutOffTimeConfigurationId}/get',
                ADD: '/cutofftimes/add',
                REMOVE: '/cutofftimes/{cutOffTimeConfigurationId}/remove',
            },
            AGREEMENTS: {
                OVERVIEW: '/agreements/overview',
                GET: '/agreements/{agreementId}/get',
                GET_APPLICATIONS: '/agreements/{agreementId}/applications/overview',
                GET_PRODUCTS: '/agreements/{agreementId}/products/overview',
                GET_QUANTITES_FOR_PRICING: '/agreements/quantities/{productHash}/{productTemplateId}/getForPricingRule',
                GET_QUANTITIES_BY_SKU: '/agreements/quantities/getQuantitiesBySku',
                ADD: '/agreements/create',
                UPDATE: '/agreements/{agreementId}/update',
                GENERATE: '/agreements/{agreementId}/generate',
                LIST_COUNTRIES: '/agreements/{agreementId}/listagreementcountries',
                APPLICATIONS: {
                    OVERVIEW: '/agreements/applications/overview',
                    ADD: '/agreements/{agreementId}/applications/add',
                    REMOVE: '/agreements/{agreementId}/applications/remove',
                },
                PRODUCTS: {
                    OVERVIEW: '/agreements/products/overview',
                    SKUS: '/agreements/products/{productHash}/skus/get',
                    ADD: '/agreements/{agreementId}/{productHash}/save',
                    REMOVE: '/agreements/{agreementId}/{agreementProductId}/remove',
                    GET_SHIPS_TO: '/agreements/products/getShipsTo',
                },
                GET_ATTRIBUTES_FOR_FUNNEL: '/agreements/attributes/funnel/{productHash}/{shopHash}',
            },
            CUSTOM_CARRIER_COSTS: {
                OVERVIEW: '/customcarriercosts/overview',
                CREATE: '/customcarriercosts/create',
                DELETE: '/customcarriercosts/{customCarrierCostId}/remove',
                UPDATE: '/customcarriercosts/{customCarrierCostId}/update',
                GET_SHIPS_TO: '/customcarriercosts/{shopHash}/get-ships-to'
            },
            SUPPLIERS: {
                OVERVIEW: '/suppliers/overview',
                GET: '/suppliers/get/{supplierId}',
                GET_SUPPLIER_STATISTICS: '/suppliers/getSupplierStatistics/{supplierSourceId}/{supplierId}',
                GET_BY_ORDER_LINE_IDENTIFIER: '/suppliers/getByOrderLineIdentifier',
                UPDATE: '/suppliers/update/{supplierId}',
                ADD: '/suppliers/add',
                ONBOARD: '/suppliers/onboard/{supplierId}',
                LOCATIONS: '/suppliers/{supplierId}/locations/list',
                ALL_LOCATIONS: '/suppliers/locations',
                LOCATION_ADD: '/suppliers/{supplierId}/locations/add',
                LOCATION_UPDATE: '/suppliers/{supplierId}/locations/{locationId}/update',
                LOCATION_DELETE: '/suppliers/{supplierId}/locations/{locationId}/delete',
                LOCATION_SET_STATUS: '/suppliers/{supplierId}/locations/{locationId}/setStatus',
                LOCATION_SERVICELEVELS: '/suppliers/{supplierId}/locations/{locationId}/getServiceLevelsByLocationId',
                LOCATION_SERVICELEVELS_COUNTRIES: '/suppliers/locations/getCountriesToByServiceLevelIds',
                LOCATIONS_WITH_SERVICELEVELS_WITH_COUNTRIES: '/suppliers/{supplierId}/locations/withServiceLevelsAndCountries',
                LOCATION_SET_HOLIDAYS: '/suppliers/{supplierId}/locations/{locationId}/setHolidays',
                LOCATION_GET_HOLIDAYS: '/suppliers/{supplierId}/locations/{locationId}/getHolidays',
                LOCATION_SET_BULK_HOLIDAYS: '/suppliers/locations/setBulkHolidays',
                LOCATION_SET_CONTACT_PERSONS: '/suppliers/{supplierId}/locations/{locationId}/setContactPersons',
                PRODUCTS: '/products/generation/overview/{supplierId}',
                PRODUCT_TEMPLATES: '/products/generation/overview/{supplierId}/templates',
                PACKAGES_CONFIGURATION: {
                    OVERVIEW: '/suppliers/{supplierId}/packageconfiguration/overview',
                    ADD: '/suppliers/{supplierId}/packageconfiguration/create',
                    UPDATE: '/suppliers/{supplierId}/packageconfiguration/{packageId}/update',
                },
                HOLIDAYS: {
                    OVERVIEW: '/suppliers/{supplierId}/holidays/list',
                    ADD: '/suppliers/{supplierId}/holidays/add',
                    UPDATE: '/suppliers/{supplierId}/holidays/{holidayId}/update',
                },
                TEMPLATES: {
                    UPLOAD_BULK: '/suppliers/{supplierId}/templates/uploadurl/{templateSection}',
                    OVERVIEW: '/suppliers/{supplierId}/templates/list/{templateSection}',
                    ADD: '/suppliers/{supplierId}/templates/add/{templateSection}',
                },
                IMPORTED_PRODUCTS: {
                    CREATE: '/suppliers/{supplierId}/producttemplates/importedproducts/create',
                    LIST: '/suppliers/{supplierId}/producttemplates/importedproducts/list',
                    GET: '/suppliers/{supplierId}/producttemplates/importedproducts/{supplierProductImportedId}/get',
                    CHECK_CHANGES: '/suppliers/{supplierId}/producttemplates/importedproducts/{supplierProductImportedId}/checkChanges',
                    UPDATE: '/suppliers/{supplierId}/producttemplates/importedproducts/{supplierProductImportedId}/update',
                    GET_CSV_UPLOAD_URL: '/suppliers/{supplierId}/producttemplates/importedproducts/{supplierProductImportedId}/upload',
                    DOWNLOAD_CSV: '/suppliers/{supplierId}/producttemplates/importedproducts/{supplierProductImportedId}/download',
                    REVOKE: '/suppliers/{supplierId}/producttemplates/importedproducts/{supplierProductImportedId}/revoke',
                    GENERATE_TEMPLATE: '/suppliers/{supplierId}/producttemplates/importedproducts/generateTemplate',
                    LIST_IMPORTED_VALUES: '/suppliers/{supplierId}/producttemplates/importedproducts/{supplierProductImportedId}/listImportedValues',
                    PUBLISH: '/suppliers/{supplierId}/producttemplates/importedproducts/{supplierProductImportedId}/publish',
                    CLONE: '/suppliers/{supplierId}/producttemplates/importedproducts/{supplierProductImportedId}/clone',
                    LIST_DATA_SAMPLE: '/suppliers/{supplierId}/producttemplates/importedproducts/{supplierProductImportedId}/listDataSample',
                    GET_ATTRIBUTES_BY_PRODUCT_FAMILY: '/suppliers/{supplierId}/producttemplates/importedproducts/getAttributesByProductFamily',
                    GENERATE_REPORT: '/suppliers/{supplierId}/producttemplates/importedproducts/{supplierProductImportedId}/generateReport'
                },
                TEMPLATES_FOR_APPROVAL: {
                    LIST: '/suppliers/{supplierId}/producttemplates/templatesforapproval/listProductConfigurationTemplatesForApproval',
                    LIST_CONFIGURATION_PROPOSITIONS: '/suppliers/{supplierId}/producttemplates/templatesforapproval/{productConfigurationId}/listProductConfigurationPropositions',
                    ACCEPT_PROPOSITION: '/suppliers/{supplierId}/producttemplates/templatesforapproval/{productConfigurationPropositionId}/accept',
                    REJECT_PROPOSITION: '/suppliers/{supplierId}/producttemplates/templatesforapproval/{productConfigurationPropositionId}/reject',
                },
                TEMPLATE_PROPAGATION: {
                    LIST: '/suppliers/{supplierId}/templatepropagation/{changeType}/overview',
                    AGGREGATELIST: '/suppliers/{supplierId}/templatepropagationaggregate/{aggregateId}/overview',
                    PROPAGATE_PRODUCT_TEMPLATE_CHANGES: '/suppliers/{supplierId}/templatechangepropagation/{productTemplateChangePropagationId}/propagateProductTemplateChanges',
                },
                COMMERCIAL_PRODUCTS:{
                    LIST: '/suppliers/{supplierId}/commercialProducts/listCommercialProducts',
                    REVOKE: '/suppliers/{supplierId}/commercialProducts/{commercialProductId}/revokeCommercialProduct',
                    CREATE: '/suppliers/{supplierId}/commercialProducts/createCommercialProduct',
                    UPDATE: '/suppliers/{supplierId}/commercialProducts/updateCommercialProduct',
                    GETBYID: '/suppliers/{supplierId}/commercialProducts/{commercialProductId}/getById'
                },
                ATTRIBUTE_MAPPINGS: {
                    OVERVIEW: '/suppliers/{supplierId}/attributemappings/overview',
                    CREATE: '/suppliers/{supplierId}/attributemappings/create',
                    UPDATE: '/suppliers/{supplierId}/attributemappings/update',
                    REVOKE: '/suppliers/{supplierId}/attributemappings/{supplierAttributeMappingId}/revoke',
                    GET: '/suppliers/{supplierId}/attributemappings/{supplierAttributeMappingId}/get',
                },
                CONTACT_PERSONS: {
                    OVERVIEW: '/suppliers/{supplierId}/contactpersons/list',
                    ADD: '/suppliers/{supplierId}/contactpersons/add',
                    UPDATE: '/suppliers/{supplierId}/contactpersons/{contactPersonId}/update',
                },
                NOTIFICATIONS: {
                    GET: '/suppliers/{supplierId}/notifications/get',
                },
            },
            PRODUCTS: {
                GENERATION: {
                    ATTRIBUTES: {
                        LIST: '/products/attributeoption/list',
                        LIST_OPTIONS: '/products/attributeoption/listoptions?attributeName={attributeName}',
                        GET: '/products/generation/attributes',
                        LIST_POSTFIXES: '/products/attributes/listAttributePostfixes',
                        VALIDATIONS: {
                            LIST: '/products/attributes/validations/list',
                        },
                        ADD_OPTION: '/products/attributeoption/add',
                        UPDATE_OPTION: '/products/attributeoption/update/{attributeId}',
                        DELETE_OPTION: '/products/attributeoption/delete',
                    },
                    LEGACY_ATTRIBUTES: {
                        LIST: '/products/legacyattribute/list',
                        LIST_OPTIONS: '/products/legacyattribute/listoptions?legacyAttributeName={legacyAttributeName}',
                        ADD_OPTION: '/products/legacyattribute/add',
                        UPDATE_OPTION: '/products/legacyattribute/update/{legacyAttributeId}',
                        GET_BY_NAME_OPTION_PRODUCTFAMILY: '/products/legacyattribute/getByNameOptionAndProductGroup',
                    },
                    ADD: '/products/generation/save/{supplierId}',
                    SAVE: '/products/generation/save/{supplierId}/{productConfigurationId}',
                    DELETE: '/products/generation/delete/{supplierId}/{productConfigurationId}',
                    GENERATE: '/products/generation/generate/{supplierId}/{productConfigurationId}',
                    SET_STATUS: '/products/updatestatus',
                    CHECK_VALIDITY: '/products/generation/check-validity'
                },
                CSV: {
                    UPLOAD: '/products/csv/uploadurl/{supplierId}/{productHash}/{productConfigurationId}',
                    DOWNLOAD: '/products/csv/downloadurl/{supplierId}/{productHash}/{productConfigurationId}',
                    UPDATE: '/products/csv/updateproductdata/{supplierId}/{productHash}/{productConfigurationId}',
                },
                TEMPLATES: {
                    UPDATE: '/products/template/settemplates/{supplierId}/{productConfigurationId}/{templateSection}',
                    UPDATEARTWORKCONFIGURATION: '/products/template/artworkconfiguration/{supplierId}/{productConfigurationId}',
                },
                VARIATIONS: {
                    GET: '/products/variations/{supplierId}/{productConfigurationId}',
                    UPDATE_BULK: '/products/variation/updateBySku',
                },
                PACKAGES: {
                    UPDATE: '/products/packages/setpackages/{supplierId}/{productConfigurationId}',
                },
                GET: '/products/generation/get/{supplierId}/{productConfigurationId}',
                APPROVAL: {
                    OVERVIEW: '/products/overviewForApproval',
                    APPROVE_ATTRIBUTE: '/products/approveAttribute',
                },
                AUTO_UPDATED: {
                    OVERVIEW: '/products/overviewAutoUpdated',
                },
                LEGACYPRODUCTS: {
                    GET: '/products/generation/getlegacyproducts',
                    GET_NAMES: '/products/legacyproductnames/get',
                    TRIGGER: '/products/generation/s2pcsv',
                },
                TASKS: {
                    DOWNLOAD: '/products/tasks/download',
                    CHECK_TOKEN_RESPONSE: '/products/tasks/result/{token}',
                },
            },
            CARRIERS: {
                OVERVIEW: '/carriers/overview',
                ADD: '/carriers/add',
                GET: '/carriers/{carrierId}',
                UPDATE: '/carriers/{carrierId}/update',
                GET_PRODUCT_TURNAROUNDS: '/carriers/getProductTurnarounds',
                SERVICELEVELS: {
                    OVERVIEW: '/carriers/{carrierId}/servicelevels/overview',
                    OVERVIEW_ALL: '/carriers/servicelevels/overview-all',
                    GET: '/carriers/{carrierId}/servicelevels/get/{serviceLevelId}',
                    ADD: '/carriers/{carrierId}/servicelevels/add',
                    UPDATE: '/carriers/{carrierId}/servicelevels/{serviceLevelId}/update',
                    CONFIGURATIONS: {
                        OVERVIEW: '/carriers/{carrierId}/servicelevels/{serviceLevelId}/configurations/overview',
                        GET: '/carriers/{carrierId}/servicelevels/{serviceLevelId}/configurations/get/{serviceLevelConfigurationId}',
                        ADD: '/carriers/{carrierId}/servicelevels/{serviceLevelId}/configurations/add',
                        UPDATE: '/carriers/{carrierId}/servicelevels/{serviceLevelId}/configurations/{serviceLevelConfigurationId}/update',
                    }
                },
                TASKS: {
                    DOWNLOAD: '/carriers/tasks/download',
                    CHECK_TOKEN_RESPONSE: '/carriers/tasks/result/{token}',
                    SYNC: '/carriers/tasks/sync',
                },
            },
            FINANCE: {
                CURRENCY: {
                    GET_TABLE: '/carriers/currencyConversionTable',
                },
            },
            PRODUCT_CONTENT: {
                PRODUCTS: {
                    LIST: '/productcontent/products/list',
                    GET: '/productcontent/products/get/{marketplaceProductId}',
                    UPDATE: '/productcontent/products/update/{section}/{marketplaceProductId}',
                    IMAGES: {
                        GET_UPLOAD_URLS: '/productcontent/images/uploadurls/{marketplaceProductId}',
                        GET_PREVIEW_URLS: '/productcontent/images/previewurls/{marketplaceProductId}',
                    },
                },
                CATEGORIES: {
                    LIST: '/productcontent/categories/list',
                    UPDATE: '/productcontent/categories/update',
                    DELETE: '/productcontent/categories/remove',
                },
                TAGS: {
                    LIST: '/productcontent/tags/list',
                },
                SUBMISSION_SPECIFICATIONS: {
                    LIST: '/productcontent/submissionspecifications/list',
                },
                FAQS: {
                    LIST: '/productcontent/faqs/list',
                },
            },
            PRICING: {
                PRICING_RULES: {
                    CREATE: '/pricing/pricingrules/create',
                    UPDATE: '/pricing/pricingrules/update',
                    DELETE: '/pricing/pricingrules/{pricingRuleId}/delete',
                    LIST: '/pricing/pricingrules/{pricingRuleTemplateId}/list',
                    UPDATE_IMPORTANCE: '/pricing/pricingrules/update-importance'
                },
                PRICING_RULE_LABELS: {
                    LIST: '/pricing/pricingrulelabels/list',
                    GETMAINLABELSANDTYPES: '/pricing/pricingrulelabels/getMainLabelsAndTypes',
                    CREATE: '/pricing/pricingrulelabels/create',
                    UPDATE: '/pricing/pricingrulelabels/update',
                    DELETE: '/pricing/pricingrulelabels/{labelId}/delete',
                },
                PRICING_RULE_LABEL_MAPPING_FILES: {
                    GET: '/pricing/pricingrulelabels/mapping-files/get',
                    UPDATE: '/pricing/pricingrulelabels/mapping-files/{environment}/update',
                    CLEAR_CACHE: '/pricing/pricingrulelabels/mapping-files/{environment}/clear-cache',
                    CSV_UPLOAD_URL: '/pricing/pricingrulelabels/mapping-files/{environment}/{fileType}/upload',
                    DOWNLOAD_CSV: '/pricing/pricingrulelabels/mapping-files/{environment}/{fileType}/download',
                },
                PRICING_RULE_TEMPLATES: {
                    LIST: '/pricing/pricingruletemplates/{agreementId}/list',
                    CREATE: '/pricing/pricingruletemplates/create',
                    UPDATE: '/pricing/pricingruletemplates/update',
                    DUPLICATE: '/pricing/pricingruletemplates/duplicate',
                    DELETE: '/pricing/pricingruletemplates/delete',
                    PUT_TO_STAGING: '/pricing/pricingruletemplates/{pricingRuleTemplateId}/putPricingTemplateToStaging',
                    PUT_TO_PRODUCTION: '/pricing/pricingruletemplates/{pricingRuleTemplateId}/putPricingTemplateToProduction',
                    GENERATE_SALES_PRICES: '/pricing/pricingruletemplates/{pricingRuleTemplateId}/{agreementId}/generateSalesPrices',
                    GET_NOT_COVERED_PRODUCTS: '/pricing/pricingruletemplates/{pricingRuleTemplateId}/getNotCoveredProducts'
                },
                MARGIN: {
                    CREATE: '/pricing/margin/create',
                    UPDATE: '/pricing/margin/update',
                    LIST: '/pricing/margin/list',
                    DELETE: '/pricing/margin/{priceMarginId}/delete'
                },
                TURNAROUND_STRATEGY: {
                    CREATE: '/pricing/turnaroundstrategy/create',
                    UPDATE: '/pricing/turnaroundstrategy/update',
                    LIST: '/pricing/turnaroundstrategy/list',
                    DELETE: '/pricing/turnaroundstrategy/{turnaroundStrategyId}/delete',
                },
                TURNAROUND_UPSELL: {
                    CREATE: '/pricing/turnaroundupsell/create',
                    UPDATE: '/pricing/turnaroundupsell/update',
                    LIST: '/pricing/turnaroundupsell/list',
                    GET: '/pricing/turnaroundupsell/{turnaroundUpsellId}/get',
                    DELETE: '/pricing/turnaroundupsell/{turnaroundUpsellId}/delete'
                },
                OPTIMIZED_SHIPPING: {
                    CREATE: '/pricing/optimizedshipping/create',
                    UPDATE: '/pricing/optimizedshipping/update',
                    LIST: '/pricing/optimizedshipping/list',
                    DELETE: '/pricing/optimizedshipping/{optimizedShippingId}/delete'
                },
                SERVICE_LEVEL_MAPPING: {
                    LIST: '/pricing/servicelevelmapping/list',
                    UPDATE: '/pricing/servicelevelmapping/update',
                    CREATE: '/pricing/servicelevelmapping/create',
                    DELETE: '/pricing/servicelevelmapping/{serviceLevelMappingId}/delete'
                },
                SERVICE_LEVEL_LABEL: {
                    CREATE: '/pricing/servicelevellabel/create',
                    DELETE: '/pricing/servicelevellabel/delete',
                    LIST: '/pricing/servicelevellabel/list'
                },
                TASKS: {
                    LIST: '/pricing/salespricetasks/list',
                    LIST_LATEST: '/pricing/salespricetasks/listLatest'
                },
                HISTORY: {
                    LIST: '/pricing/history',
                },
                PRICED_SKUS: '/pricing/pricedskus/{countryCode}/{agreementId}/{productConfigurationTemplateId}'
            }
        },
        REQUEST_DEFAULT: {
            request: {
                startRow: 0,
                endRow: 9999999,
                rowGroupCols: [],
                valueCols: [],
                pivotCols: [],
                pivotMode: false,
                groupKeys: [],
                filterModel: {},
                sortModel: [],
            },
        },
    },
    VALIDATIONS: {
        PACKAGES: {
            PACKAGE_CONFIGURATION: {
                packageType: {
                    required,
                    minLength: minLength(1),
                },
                weight: {
                    required,
                },
                multiplier: {
                    required,
                },
                maxUnitsPerPackage: {
                    required,
                },
                maxSurface: {
                    required: requiredIf(function (packageConfiguration) {
                        if (!packageConfiguration) {
                            return false
                        }
                        return ['NON_FOLDABLE', 'FOLDABLE'].includes(packageConfiguration.packageType)
                    }),
                },
                maxWidth: {
                    required: requiredIf(packageConfiguration => {
                        if (!packageConfiguration) {
                            return false
                        }
                        return packageConfiguration.packageType === 'ROLLABLE'
                    }),
                },
            },
        },
    },

    NAVIGATION: {
        LOGIN_PATH: '/login',
        LOGOUT_PATH: '/logout',
    },
    CHECK_TOKEN_RESPONSE_ATTEMPTS: 400,
    CHECK_TOKEN_RESPONSE_TIMEOUT: 2000, // 2 seconds
    GRIDS: {
        DEFAULT_ROW_HEIGHT: 48,
        MAX_DETAIl_ROW_HEIGHT: 300,
    },

    COOKIES: {
        TOKEN: {
            KEY: 'token',
        },
        LOCALE: {
            KEY: 'locale',
        },
        SESSION: {
            KEY: 'session',
        }
    },
    PACKAGES: {
        TYPES: [
            {
                value: 'FOLDABLE',
                title: 'Foldable',
                requiredFields: [
                    'weight',
                    'multiplier',
                    'maxUnitsPerPackage',
                    'maxSurface',
                ]
            },
            {
                value: 'NON_FOLDABLE',
                title: 'Non - Foldable',
                requiredFields: [
                    'weight',
                    'multiplier',
                    'maxUnitsPerPackage',
                    'maxSurface',
                ]
            },
            {
                value: 'ROLLABLE',
                title: 'Rollable',
                requiredFields: [
                    'weight',
                    'multiplier',
                    'maxUnitsPerPackage',
                    'maxWidth',
                ]
            },
            {
                value: 'UNIT',
                title: 'Unit',
                requiredFields: [
                    'weight',
                    'multiplier',
                    'maxUnitsPerPackage',
                ]
            },
        ],
    },
    STATUSSES: {
        SUPPLIERS: {
            SUPPLIER: [
                {
                    title: 'Active',
                    value: 'ACTIVE',
                },
                {
                    title: 'Inactive',
                    value: 'INACTIVE',
                },
            ],
            LOCATION: [
                {
                    title: 'Active',
                    value: 'ACTIVE',
                },
                {
                    title: 'Inactive',
                    value: 'INACTIVE',
                },
            ],
            PRODUCTS: {
                PRODUCT: [
                    {
                        title: 'Active',
                        value: 'ACTIVE',
                    },
                    {
                        title: 'Inactive',
                        value: 'INACTIVE',
                    },
                ],
            },
            VARIATIONS: {
                VARIATION: [
                    {
                        title: 'Active',
                        value: 'ACTIVE',
                    },
                    {
                        title: 'Inactive',
                        value: 'INACTIVE',
                    },
                ],
            },
        },
    },
    PRODUCT_CONTENT_MANAGEMENT: {
        FILTERS: {
            STATES: [
                {
                    title: 'Available',
                    value: 'AVAILABLE',
                },
                {
                    title: 'Unavailable',
                    value: 'UNAVAILABLE',
                },
            ],
        }
    },
    CURRENCIES: [
        {
            title: 'Euro',
            value: 'EUR',
            sign: '€',
        },
        {
            title: 'Pound sterling',
            value: 'GBP',
            sign: '£',
        },
        {
            title: 'United States dollar',
            value: 'USD',
            sign: '$',
        },
        {
            title: 'Swedish Kronor',
            value: 'SEK',
            sign: 'kr',
        },
        {
            title: 'Danish Krone',
            value: 'DKK',
            sign: 'kr.',
        },
        {
            title: 'Polish Zloty',
            value: 'PLN',
            sign: 'zł',
        },
        {
            title: 'Bulgarian Lev',
            value: 'BGN',
            sign: 'лв',
        },
        {
            title: 'Czech Koruna',
            value: 'CZK',
            sign: 'Kč',
        },
        {
            title: 'Croatian Kuna',
            value: 'HRK',
            sign: 'kn'
        },
        {
            title: 'Hungarian Forint',
            value: 'HUF',
            sign: 'ft'
        },
        {
            title: 'Romanian leu',
            value: 'RON',
            sign: 'lei'
        }
    ],
    TRANSPORT_SETTINGS: {
        FORMS_OF_TRANSPORT: [
            {
                title: 'FTP',
                value: 'Ftp\\FtpTransport',
                fields: [
                    'transportHost',
                    'transportPort',
                    'transportUsername',
                    'transportPassword',
                    'transportDirectory',
                    'transportFormat',
                    'additionalData',
                ],
            },
            {
                title: 'FTPS',
                value: 'Ftp\\FtpsTransport',
                fields: [
                    'transportHost',
                    'transportPort',
                    'transportUsername',
                    'transportPassword',
                    'transportDirectory',
                    'transportFormat',
                    'additionalData',
                ],
            },
            {
                title: 'SFTP',
                value: 'Sftp\\SftpTransport',
                fields: [
                    'transportHost',
                    'transportPort',
                    'transportUsername',
                    'transportPassword',
                    'transportDirectory',
                    'transportFormat',
                    'additionalData',
                ],
            },
            {
                title: 'HTTP Basic Auth',
                value: 'Http\\BasicAuthHttpTransport',
                fields: [
                    'transportHost',
                    'transportPort',
                    'transportUsername',
                    'transportPassword',
                    'transportFormat',
                    'additionalData',
                ],
            },
            {
                title: 'HTTP Basic',
                value: 'Http\\BasicHttpTransport',
                fields: [
                    'transportHost',
                    'transportPort',
                    'transportFormat',
                    'additionalData',
                ],
            },
            {
                title: 'HTTP HMac',
                value: 'Http\\HmacHttpTransport',
                fields: [
                    'transportHost',
                    'transportKey',
                    'transportSecret',
                    'transportFormat',
                    'additionalData',
                ],
            },
            {
                title: 'SOAP',
                value: 'soap',
                fields: [
                    'transportHost',
                    'transportPort',
                    'transportUsername',
                    'transportPassword',
                    'transportCall',
                    'additionalData',
                ],
            },
            {
                title: 'OneFlow',
                value: 'oneflow',
                fields: [
                    'transportHost',
                    'transportSecret',
                    'transportToken',
                    'additionalData',
                ],
            },
            {
                title: 'Pub/Sub',
                value: 'Specific\\PubSubTransport',
                fields: [
                    'transportTopic',
                ],
            },
            {
                title: 'Other',
                value: 'other',
                fields: [
                    'transportHost',
                    'transportPort',
                    'transportUsername',
                    'transportPassword',
                    'transportSecret',
                    'transportToken',
                    'transportDirectory',
                    'transportFormat',
                    'transportCall',
                    'otherTransportClass',
                    'additionalData',
                ],
            },
        ],
        FORMATS: [
            {
                title: 'JSON',
                value: 'json',
            },
            {
                title: 'XML',
                value: 'xml',
            },
            {
                title: 'Old XML',
                value: 'xmlOld',
            },
        ],
        SETTING_STATUSSES: [
            {
                title: 'Active',
                value: 'ACTIVE',
            },
            {
                title: 'Inactive',
                value: 'INACTIVE',
            },
            {
                title: 'Draft',
                value: 'DRAFT',
            },
        ],
        CALLBACKS: {
            DEFAULT_MERCUR_CALLBACK_CLASS: 'Specific\\MercurCallback',
        },
    },
    PRODUCT_TEMPLATE_SECTIONS: [
        {
            title: 'Supplier templates',
            value: 'supplier',
            icon: 'fas fa-vector-square',
            requiredPermissions: [
                'SupplierCentral/updateProductConfigurationTemplates',
            ],
        },
        {
            title: 'Artwork configurations',
            value: 'artwork',
            icon: 'fas fa-image',
            requiredPermissions: [
                'SupplierCentral/updateProductArtworkConfigurations',
            ],
        },
    ],
    ARTWORK_CONFIGURATION: {
        FIELDS: [
            {
                title: 'Auto Check allowed',
                value: 'autoCheckAllowed',
                type: 'checkbox',
                suffix: '',
                section: 'general',
            },
            {
                title: 'PDF Version',
                value: 'pdfVersion',
                type: 'select',
                suffix: '',
                section: 'general',
                options: [
                    {
                        title: 'Acrobat 1.7 (PDF 1.6)',
                        value: 'Acrobat 1.7 (PDF 1.6)',
                    },
                    {
                        title: 'Acrobat 1.4 (PDF 1.3)',
                        value: 'Acrobat 1.4 (PDF 1.3)',
                    },
                ],
            },
            {
                title: 'Page count',
                value: 'pageCount',
                type: 'checkbox',
                suffix: '',
                section: 'general',
            },
            {
                title: 'Minimum DPI',
                value: 'minimumDpi',
                type: 'text',
                suffix: '',
                section: 'general',
            },
            {
                title: 'Layered',
                value: 'layered',
                type: 'checkbox',
                suffix: '',
                section: 'general',
            },
            {
                title: 'Internal name',
                value: 'internalName',
                type: 'text',
                suffix: '',
                section: 'general',
            },
            {
                title: 'Manual Export Setting',
                value: 'manualExportSetting',
                type: 'select',
                section: 'general',
                options: [
                    {
                        title: '1_Standaard',
                        value: '1_Standaard',
                    },
                    {
                        title: '2_Exceptional',
                        value: '2_Exceptional',
                    },
                    {
                        title: '3_Pens & Textile',
                        value: '3_Pens & Textile',
                    },
                    {
                        title: '4_Flyeralarm',
                        value: '4_Flyeralarm',
                    },
                    {
                        title: '5_Newspaper',
                        value: '5_Newspaper',
                    },
                    {
                        title: '7_Layered',
                        value: '7_Layered',
                    },
                    {
                        title: 'N/A',
                        value: 'N/A',
                    },
                ],
            },
            {
                title: 'Cut Contour check',
                value: 'cutContourCheck',
                type: 'checkbox',
                suffix: '',
                section: 'general',
            },
            {
                title: 'Font Outlined',
                value: 'fontOutlined',
                type: 'checkbox',
                suffix: '',
                section: 'general',
            },
            {
                title: 'Bleed',
                value: 'bleed',
                type: 'text',
                suffix: 'mm',
                section: 'general',
                initialValue: 5,
            },
            {
                title: 'Margin',
                value: 'margin',
                type: 'text',
                suffix: 'mm',
                section: 'general',
                initialValue: 5,
            },
            {
                title: 'Crop Marks',
                value: 'cropMarks',
                type: 'checkbox',
                suffix: '',
                section: 'general',
            },
            {
                title: 'Artwork Check Grade',
                value: 'artworkCheckGrade',
                type: 'select',
                suffix: '',
                section: 'general',
                options: [
                    {
                        title: 'Intermediate',
                        value: 'Intermediate',
                    },
                    {
                        title: 'Advanced',
                        value: 'Advanced',
                    },
                    {
                        title: 'Expert',
                        value: 'Expert',
                    },
                    {
                        title: 'Master',
                        value: 'Master',
                    },
                    {
                        title: 'Specialist',
                        value: 'Specialist',
                    },
                ]
            },
            {
                title: 'Transparency',
                value: 'transparency',
                type: 'checkbox',
                suffix: '',
                section: 'general',
            },
            {
                title: 'DTP Export Setting',
                value: 'dtpExportSetting',
                type: 'select',
                suffix: '',
                section: 'general',
                options: [
                    {
                        title: '1_Standaard',
                        value: '1_Standaard',
                    },
                    {
                        title: '2_Exceptional',
                        value: '2_Exceptional',
                    },
                    {
                        title: '3_Pens & Textile',
                        value: '3_Pens & Textile',
                    },
                    {
                        title: '4_Flyeralarm',
                        value: '4_Flyeralarm',
                    },
                    {
                        title: '5_Newspaper',
                        value: '5_Newspaper',
                    },
                    {
                        title: '7_Layered',
                        value: '7_Layered',
                    },
                ],
            },
            {
                title: 'Colour Profile Link',
                value: 'colourProfileLink',
                type: 'file',
                section: 'files',
                filterParams: {
                    artworkConfigurationFileType: 'COLOR_PROFILE',
                },
            },
            {
                title: 'Proof Creation',
                value: 'proofCreation',
                type: 'text',
                suffix: '',
                section: 'files',
            },
            {
                title: 'Proofing Link',
                value: 'proofingLink',
                type: 'file',
                section: 'files',
                filterParams: {
                    artworkConfigurationFileType: 'AUTOCHECK_TEMPLATE',
                },
            },
            {
                title: 'Proofing Name',
                value: 'proofingName',
                type: 'text',
                section: 'files',
            },
            {
                title: 'Manual Template Link',
                value: 'manualTemplateLink',
                type: 'file',
                section: 'files',
                filterParams: {
                    artworkConfigurationFileType: 'MANUAL_TEMPLATE',
                },
            },
        ],
    },
    CUT_OFF_TIME_ADJUSTMENT: {
        FIELDS: [
            {
                title: 'Facility',
                value:  'facilityId',
                type: 'select',
                selectLabel: 'locationName',
                selectKey: 'facilities',
                reduceKey: 'locationId',
            },
            {
                title: 'Product configuration id',
                value:  'productConfigurationId',
                type: 'select',
                selectLabel: 'productConfigurationName',
                selectKey: 'products',
                reduceKey: 'productConfigurationId',
            },
            {
                title: 'Country',
                value:  'countryCode',
                type: 'select',
                selectLabel: 'countryName',
                selectKey: 'countries',
                reduceKey: 'countryShortCode',
            },
        ]
    },
    NOTIFICATIONS: {
        SUPPLIER_ACCOUNT: [
            {
                sectionTitle: 'Order line status',
                sectionItems: [
                    {
                        title: `Changes to 'Ready for production'`,
                        value: 'OrderLineStatusWasSetToReadyForProduction',
                    },
                ]
            },
            {
                sectionTitle: 'Quotes',
                sectionItems: [
                    {
                        title: 'New request for quote proposal',
                        value: 'SendSupplierQuoteToParticipantWasRequested',
                    },
                ]
            },
            {
                sectionTitle: 'Complaints',
                sectionItems: [
                    {
                        title: `Complaint was shared with '{{supplierName}}'`,
                        value: 'ComplaintWasSharedWithSupplier',
                    },
                ]
            },
        ],
    },
    TRANSLATIONS: {
        'cost_price': 'Cost Price',
        'quantity': 'Quantity'
    },
}
